<template>
  <div class="tag-list-chip" :class="{ selected }" @click="handleClick(tag.id)">
    <div class="text">{{ tag.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'TagListChip',

  props: {
    selectedTagIds: { type: Array, default: () => [] },
    tag: { type: Object, default: () => {} }
  },

  computed: {
    selected() {
      return this.selectedTagIds.includes(this.tag.id)
    }
  },

  methods: {
    handleClick(tagId) {
      this.$emit('select-tag', tagId)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list-chip {
  cursor: default;
  box-sizing: border-box;
  padding: 0 8px;
  min-width: 60px;
  height: 24px;
  color: #{$gray};
  font-size: 12px;
  text-align: center;
  border: 1px solid #{$gray};
  border-radius: 12px;
  display: flex;
  text-align: center;
  justify-content: center;
  @include hover();
  &.selected {
    background-color: #{$gray};
    color: #{$white};
  }
  > .text {
    line-height: 22px;
  }
}
</style>
