<template>
  <div class="tag-list">
    <tag-list-chip
      v-for="tag in tags"
      :key="tag.id"
      :selectedTagIds="selectedTagIds"
      :tag="tag"
      @select-tag="selectTag"
    />
  </div>
</template>

<script>
import TagListChip from '@/components/parts/atoms/TagListChip'

export default {
  name: 'TagList',

  components: { TagListChip },

  props: {
    selectedTagIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] }
  },

  methods: {
    selectTag(tagId) {
      this.$emit('select-tag', tagId)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
